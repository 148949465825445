import Cookies from 'js-cookie';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, resetStore } = actions;

const login = () => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      setTimeout(() => {
        Cookies.set('logedIn', true);
        return dispatch(loginSuccess(true));
      }, 500);
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      setTimeout(() => {
        Cookies.remove('logedIn');
        dispatch(resetStore()); // Dispatch resetStore action here
        dispatch(logoutSuccess(null));
      }, 500);
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, resetStore };
