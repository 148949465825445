import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import withAdminLayout from '../layout/withAdminLayout';

const Dashboard = lazy(() => import('../views/dashboard'));
const Chat = lazy(() => import('../views/chats/ChatsApp'));
const Myprofile = lazy(() => import('../container/profile/myProfile/Index'));
const AccountsTable = lazy(() => import('../views/accounts/AccountsApp'));
const Connections = lazy(() => import('../views/connections/ConnectionsApp'));
const Integrations = lazy(() => import('../views/integrations/IntegrationsApp'));
const InviteCampaigns = lazy(() => import('../views/invites/campaigns/InviteCampaignsApp'));
const InviteCampaignEdit = lazy(() => import('../views/invites/campaigns/EditInviteCampaign'));
const KommoPage = lazy(() => import('../views/integrations/kommo/SuccessPage'));
const Engagements = lazy(() => import('../views/engagements/EngagementsPage'));
const Posts = lazy(() => import('../views/posts/PostsApp'));
const WizzardPosts = lazy(() => import('../views/posts/WizzardPosts'));
const Support = lazy(() => import('../views/support/SupportPage'));
const Sequences = lazy(() => import('../views/sequences/SequencesApp'));
const EditSequence = lazy(() => import('../views/sequences/EditSequence'));
const WizzardSequence = lazy(() => import('../views/sequences/WizzardSequence'));
const ResponsePage = lazy(() => import('../views/fondy/ResponsePage'));
const InvoicesApp = lazy(() => import('../views/billing/InvoicesApp'));
const InvoiceViewApp = lazy(() => import('../views/billing/InvoiceViewApp'));
const WizzardApp = lazy(() => import('../views/wizzard/WizzardApp'));
const WizzardInviteCampaigns = lazy(() => import('../views/invites/campaigns/WizzardInviteCampaigns'));
const LndApp = lazy(() => import('../views/lnd/lndApp'));
const ErrorPage = lazy(() => import('../views/error-page/ErrorPage'));
const ReportsApp = lazy(() => import('../views/reports/ReportsApp'));

function Admin() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/accounts`} component={AccountsTable} />
        <Route path={`${path}/contacts/:searchText`} component={Connections} />
        <Route exact path={`${path}/contacts`} component={Connections} />
        <Route exact path={`${path}/integrations`} component={Integrations} />
        <Route exact path={`${path}/invite-campaigns`} component={InviteCampaigns} />
        <Route exact path={`${path}/invite-campaigns/wizzard`} component={WizzardInviteCampaigns} />
        <Route exact path={`${path}/invite-campaigns/edit/:tab/:campaignId?`} component={InviteCampaignEdit} />
        <Route exact path={`${path}/integrations/kommo/success`} component={KommoPage} />
        <Route exact path={`${path}/engagements`} component={Engagements} />
        <Route exact path={`${path}/posts`} component={Posts} />
        <Route exact path={`${path}/posts/wizzard`} component={WizzardPosts} />
        <Route exact path={`${path}/support`} component={Support} />
        <Route exact path={`${path}/sequences`} component={Sequences} />
        <Route exact path={`${path}/sequences/edit/:sequenceId`} component={EditSequence} />
        <Route exact path={`${path}/sequences/add`} component={EditSequence} />
        <Route exact path={`${path}/sequences/wizzard`} component={WizzardSequence} />
        <Route exact path={`${path}/fondy/response`} component={ResponsePage} />
        <Route exact path={`${path}/billing`} component={InvoicesApp} />
        <Route exact path={`${path}/billing/invoice/:invoiceId`} component={InvoiceViewApp} />
        <Route exact path={`${path}/wizzard`} component={WizzardApp} />
        <Route exact path={`${path}/reports`} component={ReportsApp} />
        <Route path={`${path}/chat/:connectionIdFromUrl/:connectionFullNameFromUrl`} component={Chat} />
        <Route path={`${path}/chats`} component={Chat} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />
        <Route path={`${path}/lndApp`} component={LndApp} />
        <Route path={`${path}/error-page/:errorType`} component={ErrorPage} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);
