import React, { useState } from 'react';
// import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import {
  InfoWraper,
  NavAuth,
  // UserDropDwon
} from './auth-info-style';
// import Message from './message';
import Notification from './notification';
// import Invite from './invite';
import Settings from './settings';
import Support from './support';
// import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
// import Heading from '../../heading/heading';

function AuthInfo() {
  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector((state) => {
    return {
      isAuthenticate: state.fb.auth.uid,
    };
  });

  const [state, setState] = useState({
    flag: 'english',
  });
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    if (isAuthenticate) {
      dispatch(fbAuthLogout(dispatch(logOut())));
    } else {
      dispatch(logOut());
    }
  };

  // const userContent = (
  //   <UserDropDwon>
  //     <div className="user-dropdwon">
  //       <figure className="user-dropdwon__info">
  //         <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
  //         <figcaption>
  //           <Heading as="h5">Abdullah Bin Talha</Heading>
  //           <p>UI Expert</p>
  //         </figcaption>
  //       </figure>
  //       <ul className="user-dropdwon__links">
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="user" /> Profile
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="settings" /> Settings
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="dollar-sign" /> Billing
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="users" /> Activity
  //           </Link>
  //         </li>
  //         <li>
  //           <Link to="#">
  //             <FeatherIcon icon="bell" /> Help
  //           </Link>
  //         </li>
  //       </ul>
  //       <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
  //         <FeatherIcon icon="log-out" /> Sign Out
  //       </Link>
  //     </div>
  //   </UserDropDwon>
  // );

  const onFlagChangeHandle = (value) => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="English Version" height={24} />
        <span>English</span>
      </Link>
      {/* <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link> */}
      <Link onClick={() => onFlagChangeHandle('spain')} to="/sp">
        <img src={require('../../../static/img/flag/spain.png')} alt="Spanish Version" height={24} />
        <span>Spanish</span>
      </Link>
      {/* <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link> */}
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      <Notification />
      <Settings />
      <Support />
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt={`${flag} version`} height={18} />
          </Link>
        </Dropdown>
      </div> */}
      <div className="nav-author">
        <a href="#" className="head-example" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" />
          {/* Sign Out */}
        </a>
        {/* <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover> */}
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
