import { CREATE_SEQUENCE, DELETE_SEQUENCE, LOAD_SEQUENCES, UPDATE_SEQUENCE, RESET_STORE } from './actions';

const initialState = {
  data: [], // Initialize 'data' as an array for sequences
};

const sequenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SEQUENCES:
      // Replace the existing 'data' array with the new sequences
      return {
        ...state,
        data: action.sequences, // Assuming 'action.sequences' is an array of sequence objects
      };
    case CREATE_SEQUENCE:
      // Add the new sequence to the 'data' array
      return {
        ...state,
        data: [...state.data, action.sequence], // Assuming 'action.sequence' is the new sequence object
      };
    case UPDATE_SEQUENCE:
      // Update the sequence in the 'data' array
      return {
        ...state,
        data: state.data.map(
          (sequence) => (sequence.id === action.sequence.id ? action.sequence : sequence), // Assuming 'action.sequence' is the updated sequence object
        ),
      };
    case DELETE_SEQUENCE:
      // Remove the sequence from the 'data' array
      return {
        ...state,
        data: state.data.filter((sequence) => sequence.id !== action.sequenceId), // Assuming 'action.sequenceId' is the id of the sequence to delete
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default sequenceReducer;
