import { LOAD_LIB_LANGUAGES } from './actions';

const initialState = {
  data: [],
};

const libLanguagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIB_LANGUAGES:
      return {
        ...state,
        data: action.libLanguages,
      };
    // ... (other cases here if needed)
    default:
      return state;
  }
};

export default libLanguagesReducer;
