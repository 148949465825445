import { UPDATE_PAYMENT_METHOD, LOAD_PAYMENT_METHODS, DELETE_PAYMENT_METHOD, RESET_STORE } from './actions';

const initialState = {
  data: [],
};

const paymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PAYMENT_METHODS:
      return {
        ...state,
        data: action.paymentMethods,
      };
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        data: action.paymentMethod,
      };
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        data: action.paymentMethods,
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default paymentMethodReducer;
