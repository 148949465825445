import { CREATE_ORDER, DELETE_ORDER, LOAD_ORDERS, UPDATE_ORDER, RESET_STORE } from './actions';

const initialState = {
  data: [], // Initialize 'data' as an array to hold orders
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ORDERS:
      return {
        ...state,
        data: action.orders,
      };
    case CREATE_ORDER:
      return {
        ...state,
        data: [...state.data, action.order],
      };
    case UPDATE_ORDER:
      return {
        ...state,
        data: state.data.map((order) => (order.id === action.order.id ? action.order : order)),
      };
    case DELETE_ORDER:
      return {
        ...state,
        data: state.data.filter((order) => order.id !== action.orderId),
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default orderReducer;
