import { CREATE_MESSAGE, DELETE_MESSAGE, LOAD_MESSAGES, UPDATE_MESSAGE, RESET_STORE } from './actions';

const initialState = {
  data: [],
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MESSAGES:
      return {
        ...state,
        data: action.messages,
      };
    // case MARK_MESSAGES_READ:
    //   return {
    //     ...state,
    //     chatConnections: action.chatConnections,
    //   }; moved to connectionReducer
    case CREATE_MESSAGE:
      return {
        ...state,
        data: [...state.data, action.message],
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        data: [...state.data, action.message],
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        data: state.data.filter((msg) => msg.id !== action.messageId),
      };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default messageReducer;
