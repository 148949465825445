export const LOAD_CONNECTIONS = 'LOAD_CONNECTIONS';
export const LOAD_CHAT_CONNECTIONS = 'LOAD_CHAT_CONNECTIONS';
export const CREATE_CONNECTION = 'CREATE_CONNECTION';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const DELETE_CONNECTION = 'DELETE_CONNECTION';
export const UPDATE_UNREAD_MESSAGE_COUNT_OPTIMISTICALLY = 'UPDATE_UNREAD_MESSAGE_COUNT_OPTIMISTICALLY';

export const LOAD_SIDEBAR_CONNECTION = 'LOAD_SIDEBAR_CONNECTION';
export const UPDATE_SIDEBAR_CONNECTION = 'UPDATE_SIDEBAR_CONNECTION';

export const RESET_STORE = 'RESET_STORE';
