import { LOAD_LIB_FEATURED_COMPANIES } from './actions';

const initialState = {
  data: [],
};

const libFeaturedCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIB_FEATURED_COMPANIES:
      return {
        ...state,
        data: action.libFeaturedCompanies,
      };

    default:
      return state;
  }
};

export default libFeaturedCompaniesReducer;
