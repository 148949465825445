import { CREATE_INVITE, DELETE_INVITE, LOAD_INVITES, UPDATE_INVITE, RESET_STORE } from './actions';

const initialState = {
  data: [],
};

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INVITES:
      // Assuming action.invites is an array of invite objects
      return {
        ...state,
        data: action.invites,
      };
    case CREATE_INVITE:
      // Assuming action.invite is the new invite object
      return {
        ...state,
        data: [...state.data, action.invite],
      };
    case UPDATE_INVITE:
      // Assuming action.invite is the updated invite object with an id
      return {
        ...state,
        data: state.data.map((invite) => (invite.id === action.invite.id ? action.invite : invite)),
      };
    case DELETE_INVITE:
      // Assuming action.inviteId is the id of the invite to delete
      return {
        ...state,
        data: state.data.filter((invite) => invite.id !== action.inviteId),
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default inviteReducer;
