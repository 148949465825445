import { CREATE_ACCOUNT, DELETE_ACCOUNT, LOAD_ACCOUNTS, UPDATE_ACCOUNT, RESET_STORE } from './actions';

const initialState = {
  data: [],
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ACCOUNTS:
      // Assuming action.accounts is an array of account objects
      return {
        ...state,
        data: action.accounts,
      };
    case CREATE_ACCOUNT:
      // Assuming action.account is the new account object
      return {
        ...state,
        data: [...state.data, action.account],
      };
    case UPDATE_ACCOUNT:
      // Assuming action.account is the updated account object with an id
      return {
        ...state,
        data: state.data.map((account) => (account.id === action.account.id ? action.account : account)),
      };
    case DELETE_ACCOUNT:
      // Assuming action.accountId is the id of the account to delete
      return {
        ...state,
        data: state.data.filter((account) => account.id !== action.accountId),
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default accountReducer;
