import { LOAD_LIB_ERRORS } from './actions';

const initialState = {
  data: [],
};

const libErrorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIB_ERRORS:
      return {
        ...state,
        data: action.libErrors,
      };
    // Other cases...
    default:
      return state;
  }
};

export default libErrorsReducer;
