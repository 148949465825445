import { CREATE_POST, DELETE_POST, LOAD_POSTS, UPDATE_POST, RESET_STORE } from './actions';

const initialState = {
  data: [], // Initialize 'data' as an array
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_POSTS:
      // Replace the existing 'data' array with the new posts
      return {
        ...state,
        data: action.posts, // Assuming 'action.posts' is an array of post objects
      };
    case CREATE_POST:
      // Add the new post to the 'data' array
      return {
        ...state,
        data: [...state.data, action.post], // Assuming 'action.post' is the new post object
      };
    case UPDATE_POST:
      // Update the post in the 'data' array
      return {
        ...state,
        data: state.data.map(
          (post) => (post.id === action.post.id ? action.post : post), // Assuming 'action.post' is the updated post object
        ),
      };
    case DELETE_POST:
      // Remove the post from the 'data' array
      return {
        ...state,
        data: state.data.filter((post) => post.id !== action.postId), // Assuming 'action.postId' is the id of the post to delete
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default postReducer;
