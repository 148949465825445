import { LOAD_LIB_AI_POST_TYPES_CONTEXTS } from './actions';

const initialState = {
  data: [],
};

const libAiPostTypesContextsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIB_AI_POST_TYPES_CONTEXTS:
      return {
        ...state,
        data: action.libAiPostTypesContexts,
      };
    // Other cases...
    default:
      return state;
  }
};

export default libAiPostTypesContextsReducer;
