import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Row, Col, Popover, Button } from 'antd';
import { CreditCardTwoTone } from '@ant-design/icons';
import { SettingDropdwon } from './auth-info-style';
// import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

function Settings() {
  const [open, setOpen] = useState(false);

  const closePopover = () => {
    setOpen(false);
  };

  const togglePopover = () => {
    setOpen(!open);
  };

  const content = (
    <SettingDropdwon>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12}>
            <Link to="/v/integrations" style={{ padding: 0 }} onClick={closePopover}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/icon-connect-48.png')} alt="" height="30px" />
                <figcaption>
                  <Heading as="h5">Integrations</Heading>
                  <p>Zapier, CRM etc. </p>
                </figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={12}>
            <Link to="/v/engagements" style={{ padding: 0 }} onClick={closePopover}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/icon-swiping-heart-right-48.png')} alt="" height="30px" />
                <figcaption>
                  <Heading as="h5">Engagements</Heading>
                  <p>Likes, comments, endorses...</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={12}>
            <Link to="/v/billing" style={{ padding: 0 }} onClick={closePopover}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/icon-2card-48.png')} alt="" height="30px" />
                <figcaption>
                  <Heading as="h5">Billing</Heading>
                  <p> Invoices & subscriptions</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={12}>
            <Link to="/v/partner" style={{ padding: 0 }} onClick={closePopover}>
              <figure className="setting-dropdwon__single d-flex">
                <img src={require('../../../static/img/icon/icon-online-support.png')} alt="" height="30px" />
                <figcaption>
                  <Heading as="h5">Partners</Heading>
                  <p>Partner with us and share our success</p>
                </figcaption>
              </figure>
            </Link>
          </Col>
        </Row>
      </div>
    </SettingDropdwon>
  );

  return (
    <div className="settings">
      <Popover placement="bottomRight" content={content} action="hover" trigger="hover" open={open}>
        <Link to="#" className="head-example" onClick={togglePopover}>
          <FeatherIcon icon="settings" size={20} />
        </Link>
      </Popover>
    </div>
  );
}

export default Settings;
