import { LOAD_VERIFICATION_DATA } from './actions';

const initialState = {
  data: [], // Initialize 'data' as an array
};

const verificationDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VERIFICATION_DATA:
      // Replace the existing 'data' array with the new verification data
      return {
        ...state,
        data: action.verificationData, // Assuming 'action.verificationData' is an array of verification data
      };

    default:
      return state;
  }
};

export default verificationDataReducer;
