import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import { Form, Badge, Spin, Avatar, Select, Input } from 'antd';
import { toggleSidebar } from '../redux/themeLayout/actionCreator';
import { updateConnection, loadSidebarConnection } from '../redux/admin-connection/actionCreator';

export default function sidebarRight() {
  const [form] = Form.useForm();
  const { isSidebarVisible, sidebarUserData } = useSelector((reduxState) => {
    return {
      isSidebarVisible: reduxState.ChangeLayoutMode.isSidebarVisible,
      sidebarUserData: reduxState.connections.sidebarConnection,
    };
  });

  // console.log('sidebarUserData:', sidebarUserData);

  const {
    id,
    firstName,
    lastName,
    occupation,
    avatarUrl,
    profilePublicIdentifier,
    tags = [],
    contacts,
    note,
  } = sidebarUserData || {};

  const { TextArea } = Input;

  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleTagsChange = (changedTags) => {
    // Dispatch an action or call a function to handle the updated tags
    dispatch(updateConnection(id, { tags: changedTags }));
  };

  const handleNoteBlur = (event) => {
    // Dispatch an action or call a function to handle the updated note
    // Use form.getFieldValue('note') to get the current value of the note
    const noteValue = form.getFieldValue('note');
    dispatch(updateConnection(id, { note: noteValue }));
  };

  const Contacts = () => {
    if (!contacts || contacts === undefined) {
      return;
    }

    let contactsParsed;
    try {
      contactsParsed = JSON.parse(contacts);
    } catch (error) {
      console.error('Error parsing contacts:', error);
      contactsParsed = []; // or set to a default value as appropriate
    }

    console.log('contactsParsed:', contactsParsed);

    // Function to capitalize the first letter of each word
    const capitalizeFirstLetter = (string) => {
      let str;

      try {
        str = string.charAt(0).toUpperCase() + string.slice(1);
      } catch (error) {
        console.error('Error capitalizing string:', string);
        return string;
      }
      return str;
    };

    // Function to generate IM link based on the service
    const generateIMLink = (service, value) => {
      switch (service?.trim().toLowerCase()) {
        case 'twitter':
          return `https://twitter.com/${value}`;
        case 'skype':
          // Note: Skype links can be more complex, depending on the desired action (call/chat)
          return `skype:${value}?chat`;
        case 'google talk':
          // This is a generic link to Google Chat; direct linking to a chat with a specific person is not straightforward due to privacy reasons.
          return 'https://chat.google.com/';
        default:
          return '#'; // Fallback link or handling for unknown services
      }
    };

    const ensureHttpProtocol = (url) => {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
      }
      return url;
    };

    return (
      <div className="customizer__single">
        <h4>Contacts</h4>
        {/* Websites */}

        {contactsParsed
          ?.filter((contact) => contact.type === 'website')
          .map((contact) => (
            <div key={contact.id} className="d-flex align-items-center mr-20 mb-20">
              <FeatherIcon icon="link" size={16} />
              <a href={ensureHttpProtocol(contact.value)} target="_blank" rel="noopener noreferrer" className="ml-10">
                {contact.value}
              </a>
            </div>
          ))}
        {/* Emails */}
        {contactsParsed
          ?.filter((contact) => contact.type === 'email')
          .map((contact) => (
            <div key={contact.id} className="d-flex align-items-center mr-20 mb-20">
              <FeatherIcon icon="mail" size={16} />
              <a href={`mailto:${contact.value}`} className="ml-10">
                {contact.value}
              </a>
            </div>
          ))}
        {/* Phone Numbers */}
        {contactsParsed
          ?.filter((contact) => contact.type === 'phone')
          .map((contact) => (
            <div key={contact.id} className="d-flex align-items-center mr-20 mb-20">
              <FeatherIcon icon="phone" size={16} />
              <a href={`tel:${contact.value}`} className="ml-10">
                {contact.value}
              </a>
            </div>
          ))}
        {/* Instant Messengers */}
        {contactsParsed
          ?.filter((contact) => contact.type === 'im')
          .map(
            (contact) => (
              // contact.description && contact.value ? (
              <div key={contact.id} className="d-flex align-items-center mr-20 mb-20">
                <FeatherIcon icon="message-circle" size={16} />
                {/* Assuming contact.description contains the IM service name and contact.value the username or identifier */}
                <a
                  href={generateIMLink(contact.description, contact.value)}
                  className="ml-10"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {capitalizeFirstLetter(contact.description)}
                </a>
              </div>
            ),
            // ) : (
            //   console.error('IM contact is missing a description or value:', contact)
            // ),
          )}
      </div>
    );
  };

  useEffect(() => {
    // Check if sidebarUserData is not null or undefined
    if (sidebarUserData) {
      form.setFieldsValue({
        tags: tags ? JSON.parse(tags) : [], // Set to empty array if tags is undefined
        note: note || '', // Set to empty string if note is undefined
      });
    }
  }, [sidebarUserData, form]);

  return (
    // <div>some text here</div>
    <div className={`${isSidebarVisible ? 'customizer-wrapper show' : 'customizer-wrapper'}`}>
      <div className="customizer">
        <div className="customizer__head">
          <div className="d-flex ">
            <Avatar size="small" icon={<UserOutlined />} src={avatarUrl} className="mr-10" />
            <h4 className="customizer__title">
              <a href={`https://linkedin.com/in/${profilePublicIdentifier}`} target="_blank" rel="noopener noreferrer">
                {`${firstName} ${lastName}`}
              </a>
            </h4>
          </div>
          <span className="customizer__sub-title">{occupation}</span>
          {/* </div> */}
          <Link to="#" className="customizer-close" onClick={handleToggleSidebar}>
            <FeatherIcon icon="x" />
          </Link>
        </div>
        <div className="customizer__body">
          <Form key={`form-${id}`} form={form} name="account" layout="vertical">
            <div className="customizer__single">
              <Contacts />
              <h4>Tags</h4>
              {/* Ant Design Select for tags */}
              <Form.Item name="tags" label="">
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  onChange={handleTagsChange}
                  placeholder="Type and press enter to add a topic"
                />
              </Form.Item>
            </div>
            <div className="customizer__single">
              <h4>Notes</h4>
              <Form.Item name="note" label="">
                <TextArea rows={4} placeholder="Add notes" onBlur={handleNoteBlur} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
