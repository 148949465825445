import { LOAD_INITIALIZED_ACCOUNT, RESET_STORE } from './actions';

const initialState = {
  data: [],
};

const accountIniReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIALIZED_ACCOUNT:
      return {
        ...state,
        data: action.initializedAccount,
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default accountIniReducer;
