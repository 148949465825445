import { DataService } from '../config/dataService/dataService';

class AccountService {
  static async add(data) {
    const result = await DataService.post('/users', data);
    return result;
  }
}

export default AccountService;
