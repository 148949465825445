import {
  CREATE_INVITE_CAMPAIGN,
  DELETE_INVITE_CAMPAIGN,
  LOAD_INVITE_CAMPAIGNS,
  UPDATE_INVITE_CAMPAIGN,
  RESET_STORE,
} from './actions';

const initialState = {
  data: [],
};

const inviteCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INVITE_CAMPAIGNS:
      // Here we expect action.inviteCampaigns to be the array from the response body
      return {
        ...state,
        data: action.inviteCampaigns,
      };
    case CREATE_INVITE_CAMPAIGN:
      // Here we expect action.inviteCampaign to be the single new campaign object from the response body
      return {
        ...state,
        data: [...state.data, action.inviteCampaign],
      };
    case UPDATE_INVITE_CAMPAIGN:
      // Here we expect action.inviteCampaign to be the updated campaign object from the response body
      return {
        ...state,
        data: state.data.map((campaign) =>
          campaign.id === action.inviteCampaign.id ? action.inviteCampaign : campaign,
        ),
      };
    case DELETE_INVITE_CAMPAIGN:
      // Here we expect action.inviteCampaignId to be the ID of the campaign to delete
      return {
        ...state,
        data: state.data.filter((campaign) => campaign.id !== action.inviteCampaignId),
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default inviteCampaignReducer;
