import { LOAD_POSTS_STATS, RESET_STORE } from './actions';

const initialState = {
  data: [], // Initialize 'data' as an array
};

const postStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_POSTS_STATS:
      // Replace the existing 'data' array with the new posts
      return {
        ...state,
        data: action.postsStats,
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default postStatsReducer;
