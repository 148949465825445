import React from 'react';
import { Row, Col, Card } from 'antd';
import { Aside, Content, Title } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="">
              {/* <img src={require('../../../static/img/auth/login-back.jpg')} alt="" className="topShape" /> */}
              {/* <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" /> */}
              <Content>
                {/* <Title>Let&apos;s Generate Leads</Title> */}

                {/* <img style={{ width: '150px' }} src={require('../../../static/img/Logo_Dark.svg')} alt="" /> */}
                {/* <br /> */}
                {/* <br /> */}
                {/* <Card headless>LinkedIn Multi-Account Lead Generation Automation</Card> */}
                {/* <img
                  className="auth-content-figure"
                  src={require('../../../static/img/auth/Illustration.png')}
                  alt=""
                /> */}
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
