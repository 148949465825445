import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));

function NotFound() {
  return <Redirect to="/" />;
}

function FrontendRoutes() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      Login,
      SignUp,
      ForgotPass,
      // other lazy-loaded components if any
    ]).then(() => {
      setIsLoaded(true);
    });
  }, []);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route path="/register/:affiliateHash" component={SignUp} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/" component={Login} />
        {/* Add other routes here */}
        {isLoaded && <Route path="*" component={NotFound} />}
      </Switch>
    </Suspense>
  );
}

export default AuthLayout(FrontendRoutes);
