import { DataService } from '../config/dataService/dataService';

class ConnectionService {
  static async getAll() {
    const result = await DataService.get(`/connections/all`);
    return result;
  }

  static async getFiltered({ page, limit, searchText, selectedAccounts }) {
    const params = {
      page,
      limit,
      searchText,
      accountId: selectedAccounts,
    };
    const result = await DataService.get(`/connections`, params);
    return result;
  }

  static async getAccountsConnections() {
    const result = await DataService.get(`/connections/accounts`);
    return result;
  }

  static async getChatConnections(accountId, limit, searchText) {
    const data = {
      accountId,
      limit,
      searchText,
    };
    const result = await DataService.post(`/connections/chats`, data);
    return result;
  }

  static async add(data) {
    const result = await DataService.post('/connections', data);
    return result;
  }

  static async update(id, data) {
    const result = await DataService.patch(`/connections/${id}`, data);
    return result;
  }

  static async delete(id) {
    const result = await DataService.delete(`/connections/${id}`);
    return result;
  }

  static async markMessagesRead(accountId, connectionId) {
    const result = await DataService.patch(`/messages/mark-messages-read/${accountId}/${connectionId}`);
    return result;
  }
}

export default ConnectionService;
