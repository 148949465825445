import {
  CREATE_CONNECTION,
  DELETE_CONNECTION,
  LOAD_CONNECTIONS,
  LOAD_CHAT_CONNECTIONS,
  UPDATE_CONNECTION,
  LOAD_SIDEBAR_CONNECTION,
  UPDATE_SIDEBAR_CONNECTION,
  UPDATE_UNREAD_MESSAGE_COUNT_OPTIMISTICALLY,
  RESET_STORE,
} from './actions';

const initialState = {
  data: [],
  sidebarConnection: null,
  chatConnections: [],
};

const connectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONNECTIONS:
      // Assuming action.connections is an array of connection objects
      return {
        ...state,
        data: action.connections,
      };
    case LOAD_CHAT_CONNECTIONS:
      // Assuming action.connections is an array of connection objects
      return {
        ...state,
        chatConnections: action.chatConnections,
      };
    case CREATE_CONNECTION:
      // Assuming action.connection is the new connection object
      return {
        ...state,
        data: [...state.data, action.connection],
      };
    case UPDATE_CONNECTION:
      // Assuming action.connection is the updated connection object with an id
      return {
        ...state,
        data: state.data.map((connection) => (connection.id === action.connection.id ? action.connection : connection)),
      };
    case DELETE_CONNECTION:
      // Assuming action.connectionId is the id of the connection to delete
      return {
        ...state,
        data: state.data.filter((connection) => connection.id !== action.connectionId),
      };
    case LOAD_SIDEBAR_CONNECTION:
      return {
        ...state,
        sidebarConnection: action.payload, // Load user data into the sidebar connection
      };
    case UPDATE_SIDEBAR_CONNECTION:
      return {
        ...state,
        sidebarConnection: { ...state.sidebarConnection, ...action.payload }, // Update the user data in the sidebar connection
      };
    case UPDATE_UNREAD_MESSAGE_COUNT_OPTIMISTICALLY:
      const { accountId, connectionId } = action.payload;

      const updatedChatConnections = state.chatConnections.map((connection) => {
        if (Number(connection.accountId) === Number(accountId) && Number(connection.id) === Number(connectionId)) {
          return { ...connection, unreadMessageCount: 0 }; // Set unreadMessageCount to 0 optimistically
        }
        return connection;
      });
      return { ...state, chatConnections: updatedChatConnections };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default connectionReducer;
