import { CREATE_SETTINGS_TOUCH, UPDATE_SETTINGS_TOUCH, DELETE_SETTINGS_TOUCH, LOAD_SETTINGS_TOUCHES } from './actions';

const initialSettingsTouchesState = {
  data: [], // Initialize 'data' as an array for settings touches
};

const settingsTouchesReducer = (state = initialSettingsTouchesState, action) => {
  switch (action.type) {
    case LOAD_SETTINGS_TOUCHES:
      // Replace the existing 'data' array with the new settings touches
      return {
        ...state,
        data: action.settingsTouches, // Assuming 'action.settingsTouches' is an array of settings touch objects
      };
    case CREATE_SETTINGS_TOUCH:
      // Add the new settings touch to the 'data' array
      return {
        ...state,
        data: [...state.data, action.settingsTouch], // Assuming 'action.settingsTouch' is the new settings touch object
      };
    case UPDATE_SETTINGS_TOUCH:
      // Update the settings touch in the 'data' array
      return {
        ...state,
        data: state.data.map(
          (settingsTouch) => (settingsTouch.id === action.settingsTouch.id ? action.settingsTouch : settingsTouch), // Assuming 'action.settingsTouch' is the updated settings touch object
        ),
      };
    case DELETE_SETTINGS_TOUCH:
      // Remove the settings touch from the 'data' array
      return {
        ...state,
        data: state.data.filter((settingsTouch) => settingsTouch.id !== action.settingsTouchId), // Assuming 'action.settingsTouchId' is the id of the settings touch to delete
      };
    default:
      return state;
  }
};

export default settingsTouchesReducer;
