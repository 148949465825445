import React, { useState } from 'react';
import { Badge, Button, Modal, Input, Tooltip, notification } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';

function NotificationBox() {
  const { rtl, userID } = useSelector((state) => ({
    rtl: state.ChangeLayoutMode.rtlData,
    userID: state.fb.auth.uid,
  }));

  // console.log(userID);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // generate unique invite hash using userID
  const hashBody = `${userID}---inviteFriend`;
  const inviteHash = userID ? btoa(hashBody) : 'your-invite-hash';
  const inviteLink = `https://userarea.pershe.com/register/${inviteHash}`; // Your invite link

  const copyToClipboard = () => {
    // const inviteLink = 'https://your.invite.link'; // Your invite link
    navigator.clipboard.writeText(inviteLink).then(() => {
      notification.success({
        message: 'Link Copied!',
        description: 'Now you can share it with your friends.',
        placement: 'topRight',
      });
    });
  };

  const emailSubject = encodeURIComponent('Join Me on Pershe - LinkedIn Lead Generation Tool');
  const emailBody = encodeURIComponent(
    "I've been using Pershe to enhance my LinkedIn lead generation and thought you might be interested too. Check it out here: https://your.invite.link",
  );

  // Social Media Share URLs
  const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inviteLink)}`;
  const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(inviteLink)}`;
  const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(inviteLink)}`;
  const whatsappShareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(inviteLink)}`;

  // Open Share Links in a New Tab
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="notification">
      <Button type="primary" onClick={showModal} className="mr-20">
        Invite a Friend
      </Button>
      <Modal
        title="🌟 Invite a Friend, Get Rewards! 🌟"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <p>Share the joy! Invite your friends to join us by sending them your unique registration link.</p>{' '}
          <p>
            When your friend signs up and subscribe, both of you will receive a <b>1-month bonus</b> to enjoy our
            service even more. It&apos;s a win-win! Start sharing and enjoy your rewards together.
          </p>
          <p>👫✨ Invite Now ✨👫</p>
          <Input.Group compact>
            <Input style={{ width: 'calc(100% - 32px)' }} defaultValue={inviteLink} readOnly />
            <Tooltip title="Copy">
              <Button icon={<FeatherIcon icon="copy" />} onClick={copyToClipboard} />
            </Tooltip>
          </Input.Group>
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Tooltip title="Share on Facebook">
              <Button
                // type="primary"
                style={{ backgroundColor: '#1877F2', borderColor: '#1877F2', color: '#fff' }}
                shape="circle"
                icon={<FeatherIcon icon="facebook" />}
                onClick={() => openInNewTab(facebookShareURL)}
              />
            </Tooltip>
            <Tooltip title="Share on Twitter">
              <Button
                style={{ backgroundColor: '#00acee', borderColor: '#00acee', color: '#fff' }}
                shape="circle"
                icon={<FeatherIcon icon="twitter" />}
                onClick={() => openInNewTab(twitterShareURL)}
              />
            </Tooltip>
            <Tooltip title="Share on LinkedIn">
              <Button
                style={{ backgroundColor: '#0077B5', borderColor: '#0077B5', color: '#fff' }}
                shape="circle"
                icon={<FeatherIcon icon="linkedin" />}
                onClick={() => openInNewTab(linkedInShareURL)}
              />
            </Tooltip>
            <Tooltip title="Share on WhatsApp">
              <Button
                shape="circle"
                style={{ backgroundColor: '#25d366', borderColor: '#25d366', color: '#fff' }}
                icon={<FeatherIcon icon="message-circle" />}
                onClick={() => openInNewTab(whatsappShareURL)}
              />
            </Tooltip>
            <Tooltip title="Share via Email">
              <Button
                shape="circle"
                style={{ backgroundColor: '#34b7f1', borderColor: '#34b7f1', color: '#fff' }}
                icon={<FeatherIcon icon="mail" />}
                onClick={() => {
                  window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NotificationBox;
