import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import themeUsersReducer from './themeUsers/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { teamReducer } from './team/reducers';
import { userReducer, userGroupReducer } from './users/reducers';
import { sellersReducer } from './sellers/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import orderReducer from './admin-orders/reducers';
import galleryReducer from './gallary/reducers';
import chartContentReducer from './chartContent/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import { productReducer, SingleProductReducer } from './product/reducers';
import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
import { projectReducer, SingleProjectReducer } from './project/reducers';
import cartData from './cart/reducers';
import Todo from './todo/reducers';
import Note from './note/reducers';
import Task from './task/reducers';
import kanbanBoardReducer from './kanban/reducers';
import Contact from './contact/reducers';
import Account from './account/reducers';
import Profile from './profile/reducers';
import Calender from './calendar/reducers';
import FileManager from './fileManager/reducers';
import { axiosCrudReducer, axiosSingleCrudReducer } from './crud/axios/reducers';

import { fsCrudReducer, fsSingleCrudReducer } from './firebase/firestore/reducers';
import firebaseAuth from './firebase/auth/reducers';
import accountReducer from './admin-account/reducers';
import accountIniReducer from './admin-account-ini/reducers';
import connectionReducer from './admin-connection/reducers';
import connectionContactsReducer from './admin-connection-contacts/reducers';
import inviteCampaignsReducer from './admin-invite-campaigns/reducers';
import invitesReducer from './admin-invites/reducers';
import postsReducer from './admin-posts/reducers';
import postsStatsReducer from './admin-posts-stats/reducers';
import messageReducer from './admin-message/reducers';
import sequencesReducer from './admin-sequences/reducers';
import kommoIntegrationReducer from './admin-kommo-integration/reducers';
import libIndustriesReducer from './lib-industries/reducers';
import libLanguagesReducer from './lib-languages/reducers';
import libServicesReducer from './lib-services/reducers';
import libFeaturedCompaniesReducer from './lib-featured-companies/reducers';
import libSchoolsReducer from './lib-schools/reducers';
import libPricelistReducer from './lib-pricelist/reducers';
import settingsTouchesReducer from './admin-settings-touches/reducers';
import libErrorsReducer from './lib-errors/reducers';
import wizzardReducer from './admin-wizzard/reducers';
import libAiOptionsReducer from './lib-ai-options/reducers';
import libAiQuestionsReducer from './lib-ai-questions/reducers';
import libAiPostTypesContextsReducer from './lib-ai-post-types-contexts/reducers';
import paymentMethodReducer from './admin-payment-methods/reducers';
import subscriptionReducer from './admin-subscriptions/reducers';
import libProxiesCountriesReducer from './lib-proxy-countries/reducers';
import verificationDataReducer from './admin-verification-data/reducers';
import dashboardReducer from './admin-dashboard/reducers';
import comprehensiveReportDataReducer from './admin-comprehensive-report-data/reducers';

const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  orders: orderReducer,
  sellers: sellersReducer,
  users: userReducer,
  userGroup: userGroupReducer,
  team: teamReducer,
  auth: authReducer,
  gallery: galleryReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  products: productReducer,
  product: SingleProductReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  chat: chatReducer,
  groupChat: groupChatReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  crud: fsCrudReducer,
  singleCrud: fsSingleCrudReducer,
  cart: cartData,
  Todo,
  Note,
  Task,
  KanbanBoard: kanbanBoardReducer,
  Contact,
  Account,
  Profile,
  Calender,
  accounts: accountReducer,
  initializedAccount: accountIniReducer,
  connections: connectionReducer,
  // chatConnections: connectionReducer,
  connectionContacts: connectionContactsReducer,
  inviteCampaigns: inviteCampaignsReducer,
  invites: invitesReducer,
  posts: postsReducer,
  postsStats: postsStatsReducer,
  messages: messageReducer,
  sequences: sequencesReducer,
  kommoIntegrations: kommoIntegrationReducer,
  firebaseAuth,
  FileManager,
  AxiosCrud: axiosCrudReducer,
  SingleAxiosCrud: axiosSingleCrudReducer,
  pricelist: libPricelistReducer,
  libIndustries: libIndustriesReducer,
  libLanguages: libLanguagesReducer,
  libServices: libServicesReducer,
  libFeaturedCompanies: libFeaturedCompaniesReducer,
  libSchools: libSchoolsReducer,
  libErrors: libErrorsReducer,
  libAIOptions: libAiOptionsReducer,
  libAIQuestions: libAiQuestionsReducer,
  libAIPostTypesContexts: libAiPostTypesContextsReducer,
  libProxiesCountries: libProxiesCountriesReducer,
  settingsTouches: settingsTouchesReducer,
  wizzardTasks: wizzardReducer,
  paymentMethods: paymentMethodReducer,
  subscriptions: subscriptionReducer,
  verificationData: verificationDataReducer,
  // sidebarConnection: connectionReducer,
  // accountsConnections: connectionReducer,
  dashboard: dashboardReducer,
  comprehensiveReportData: comprehensiveReportDataReducer,
});

export default rootReducers;
