import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { menuItems } from './menuItemsList';

function MenuItems({ darkMode, toggleCollapsed, topMenu }) {
  const { path } = useRouteMatch();
  const mainPathSplit = window.location.pathname.split(path)[1].split('/');
  const defaultKey = mainPathSplit.length === 1 ? 'home' : mainPathSplit[1];

  const [openKeys, setOpenKeys] = useState(!topMenu ? [defaultKey] : []);

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={[defaultKey]}
      defaultOpenKeys={!topMenu ? [defaultKey] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {menuItems.map((item) => (
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/${item.key}`}>
                <FeatherIcon icon={item.icon} />
              </NavLink>
            )
          }
          key={item.key}
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/${item.key}`}>
            {item.label}
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
