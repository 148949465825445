import {
  CREATE_KOMMO_INTEGRATION,
  DELETE_KOMMO_INTEGRATION,
  LOAD_KOMMO_INTEGRATIONS,
  UPDATE_KOMMO_INTEGRATION,
} from './actions';

const initialState = {
  kommoIntegrationsById: {},
};

const kommoIntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_KOMMO_INTEGRATIONS:
      const kommoIntegrationsById = {};
      action.kommoIntegrations.forEach((kommoIntegration) => {
        kommoIntegrationsById[kommoIntegration.id] = kommoIntegration;
      });
      return {
        ...state,
        kommoIntegrationsById,
      };
    case CREATE_KOMMO_INTEGRATION:
    case UPDATE_KOMMO_INTEGRATION:
      return {
        ...state,
        kommoIntegrationsById: {
          ...state.kommoIntegrationsById,
          [action.kommoIntegration.id]: action.kommoIntegration,
        },
      };
    case DELETE_KOMMO_INTEGRATION:
      const newKommoIntegrationsById = { ...state.kommoIntegrationsById };
      delete newKommoIntegrationsById[action.kommoIntegrationsById];
      return {
        ...state,
        kommoIntegrationsById: newKommoIntegrationsById,
      };
    default:
      return state;
  }
};

export default kommoIntegrationReducer;
