import { CREATE_SUBSCRIPTION, CANCEL_SUBSCRIPTION, LOAD_SUBSCRIPTIONS, UPDATE_SUBSCRIPTION } from './actions';

const initialState = {
  data: [], // Initialize 'data' as an array
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUBSCRIPTIONS:
      // Replace the existing 'data' array with the new subscriptions
      return {
        ...state,
        data: action.subscriptions, // Assuming 'action.subscriptions' is an array of subscription objects
      };
    case CREATE_SUBSCRIPTION:
      // Add the new subscription to the 'data' array
      return {
        ...state,
        data: [...state.data, action.subscription], // Assuming 'action.subscription' is the new subscription object
      };
    case UPDATE_SUBSCRIPTION:
      // Update the subscription in the 'data' array
      return {
        ...state,
        data: state.data.map(
          (subscription) => (subscription.id === action.subscription.id ? action.subscription : subscription), // Assuming 'action.subscription' is the updated subscription object
        ),
      };
    case CANCEL_SUBSCRIPTION:
      // Remove the subscription from the 'data' array
      return {
        ...state,
        data: state.data.filter((subscription) => subscription.id !== action.subscriptionId), // Assuming 'action.subscriptionId' is the id of the subscription to delete
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
