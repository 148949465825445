import {
  CREATE_CONNECTION,
  DELETE_CONNECTION,
  LOAD_CONNECTIONS,
  LOAD_CHAT_CONNECTIONS,
  UPDATE_CONNECTION,
  UPDATE_UNREAD_MESSAGE_COUNT_OPTIMISTICALLY,
} from './actions';

import ConnectionService from '../../services/ConnectionService';

export const createConnection = (connection) => {
  return async (dispatch) => {
    try {
      const createdConnection = await ConnectionService.add(connection);
      dispatch({ type: CREATE_CONNECTION, connection: createdConnection.data.body });
    } catch (error) {
      // handle error
    }
  };
};

export const updateConnection = (id, connection) => {
  return async (dispatch) => {
    try {
      const updatedConnection = await ConnectionService.update(id, connection);
      dispatch({ type: UPDATE_CONNECTION, connection: updatedConnection.data.body });
    } catch (error) {
      // handle error
    }
  };
};

export const deleteConnection = (id) => {
  return async (dispatch) => {
    try {
      await ConnectionService.delete(id);
      dispatch({ type: DELETE_CONNECTION, connectionId: id });
    } catch (error) {
      // handle error
    }
  };
};

export const loadConnections = (current, pageSize, searchText) => {
  return async (dispatch) => {
    try {
      const connections = await ConnectionService.getFiltered(current, pageSize, searchText);
      dispatch({ type: LOAD_CONNECTIONS, connections: connections.data.body });
    } catch (error) {
      // handle error
    }
  };
};

export const loadChatConnections = (accountId, limit, searchText) => {
  return async (dispatch) => {
    try {
      const chatConnections = await ConnectionService.getChatConnections(accountId, limit, searchText);

      dispatch({ type: LOAD_CHAT_CONNECTIONS, chatConnections: chatConnections.data.body });
    } catch (error) {
      // handle error
    }
  };
};

export const markMessagesRead = (accountId, connectionId) => {
  return async (dispatch) => {
    // Optimistically update the state to set unreadMessageCount to 0 for the connection
    dispatch({
      type: UPDATE_UNREAD_MESSAGE_COUNT_OPTIMISTICALLY,
      payload: { accountId, connectionId },
    });

    try {
      await ConnectionService.markMessagesRead(accountId, connectionId);
    } catch (error) {
      // handle error
    }
  };
};

export const loadSidebarConnection = (userData) => ({
  type: 'LOAD_SIDEBAR_CONNECTION',
  payload: userData,
});

export const updateSidebarConnection = (updatedUserData) => ({
  type: 'UPDATE_SIDEBAR_CONNECTION',
  payload: updatedUserData,
});
