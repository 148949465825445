import {
  CREATE_CONNECTION_CONTACTS,
  DELETE_CONNECTION_CONTACTS,
  LOAD_CONNECTION_CONTACTS,
  UPDATE_CONNECTION_CONTACTS,
  RESET_STORE,
} from './actions';

const initialState = {
  data: [],
};

const connectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONNECTION_CONTACTS:
      // Assuming action.connectionContacts is an array of contact objects
      return {
        ...state,
        data: action.contacts,
      };
    case CREATE_CONNECTION_CONTACTS:
      // Assuming action.connection is the new contact object
      return {
        ...state,
        data: [...state.data, action.connection],
      };
    case UPDATE_CONNECTION_CONTACTS:
      // Assuming action.connection is the updated contact object with an id
      return {
        ...state,
        data: state.data.map((contact) => (contact.id === action.connection.id ? action.connection : contact)),
      };
    case DELETE_CONNECTION_CONTACTS:
      // Assuming action.connectionId is the id of the contact to delete
      return {
        ...state,
        data: state.data.filter((contact) => contact.id !== action.connectionId),
      };
    case RESET_STORE:
      return initialState; // Reset state to initial state
    default:
      return state;
  }
};

export default connectionReducer;
